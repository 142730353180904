
import Loading from "@/components/UI/Loading/src/Loading.vue";
import {defineComponent, reactive, ref, watch, inject} from "vue";
import axios from "@/api/axios";
import Toast from "@/components/UI/Toast";
import qs from "qs";

import Modal from "@/components/UI/Modal";
import router from "@/router";
import UiCheckbox from "@/components/UI/Checkbox";
import UiCheckboxGroup from "@/components/UI/CheckboxGroup";
import Empty from "@/components/common/Empty.vue";
import OrderRedPackage from "@/components/common/OrderRedPackage.vue";
import {getCartInfo} from "@/api/useChangeShopCar";
export default defineComponent({
  components:{
    Loading
  },
  name: "Coupon",
  data() {
    return {
      num:0,
      //用户id
      userInfo: {},
      couponList: [],
      showModal: false,
      page: 1,
      // 好友列表
      followList: [],
      // 选中好友
      followGet: '',
      //送券ID
      couponId: '',
      //暂无优惠券
      isShowNoCoupon:false,
      isGetList:true,
      searchText:'',
      loading:false,
      loadOption:{
        text: "加载中...",
        color: "#df0024",
        textColor: "#df0024",
      }
    }
  },
  mounted(): void {
    this.userInfo = inject("userInfo") as any;
    //默认查询待使用
    this.getCouponList1();
    // 监听页面滚动
    window.addEventListener('scroll', this.pageScroll, true)
  },

  methods: {
    getCouponList1(){
      this.loading=true;
      this.couponList=[]
      this.isShowNoCoupon=false
      axios.post("/M/couponlog/list?type=1", this.page)
          .then((res) => {
            console.log(res)
            this.loading=false;
            if (res.data.success) {
              this.couponList = res.data.data;
              if (this.couponList.length == 0) {
                this.isShowNoCoupon=true
              }
            } else {
              this.isShowNoCoupon=false
            }
          })
          .catch((err) => {
            this.loading=false;
            Toast({
              type: "error",
              title: '获取优惠券失败，请联系客服',
            });
          });
      this.num=0
    },
    getCouponList2(){
      this.loading=true;
      this.couponList=[]
      this.isShowNoCoupon=false
      axios.post("/M/couponlog/list?type=2", this.page)
          .then((res) => {
            console.log(res)
            this.loading=false;
            if (res.data.success) {
              this.couponList = res.data.data;
              if (this.couponList.length == 0) {
                this.isShowNoCoupon=true
              }
            } else {
              this.isShowNoCoupon=false
            }
          })
          .catch((err) => {
            this.loading=false;
            Toast({
              type: "error",
              title: '获取优惠券失败，请联系客服',
            });
          });
      this.num=1

    },
    getCouponList3(){
      this.loading=true;
      this.couponList=[]
      this.isShowNoCoupon=false
      axios.post("/M/couponlog/list?type=3", this.page)
          .then((res) => {
            console.log(res)
            this.loading=false;
            if (res.data.success) {
              this.couponList = res.data.data;
              if (this.couponList.length == 0) {
                this.isShowNoCoupon=true
              }
            } else {
              this.isShowNoCoupon=false
            }
          })
          .catch((err) => {
            console.log("err");
            this.loading=false;
            Toast({
              type: "error",
              title: '获取优惠券失败，请联系客服',
            });
          });
      this.num=2
    },
    searchUser() {
      this.loading=true;
      if(this.searchText.trim()===''||this.searchText.trim()==='1'){
        Toast({
          type: "error",
          title: '请输入正确的昵称或手机号',
        });
        this.loading=false;
        return false
      }
      axios.post("/M/couponlog/followList?name="+this.searchText+'&type=1')
          .then((res) => {
            if (res.data.success) {
              this.loading=false;
              console.log(res.data)
              this.followList = res.data.data;
              if(this.followList.length===0){
                Toast({
                  type: "error",
                  title: '抱歉，无法搜索对应的结果',
                });
              }
            } else {
              this.loading=false;
              Toast({
                type: "error",
                title: '系统问题，请联系管理员',
              });
            }
          }).catch((err)=>{
            Toast({
              type: "error",
              title: err.message,
            });
          }

      )
    },
    focusFriends(uid: any,index: any){
      axios.post('M/BBSServer/Follow', qs.stringify({
            userid: uid,
          }),
          {
            params: {
              validate: true,
            },
          }).then((res) => {
        console.log(res)
        if (res.data.success) {
          console.log(res.data);
          Toast({
            type: "success",
            title: '关注成功',
          });
          this.loading=false;
          this.followList[index].isFollow=1
        } else {
          this.loading=false;
          Toast({
            type: "error",
            title: '操作失败，请联系管理员',
          });
        }
      }).catch((err)=>{
            Toast({
              type: "error",
              title: '操作失败，请联系管理员',
            });
          }

      )
    },
    noFocusFriends(uid: any,index: any){
      axios.post('M/BBSServer/CancelFollow', qs.stringify({
            userid: uid,
          }),
          {
            params: {
              validate: true,
            },
          }).then((res) => {
        console.log(res)
        if (res.data.success) {
          console.log(res.data);
          Toast({
            type: "success",
            title: '取消关注成功',
          });
          this.loading=false;
          this.followList[index].isFollow=0
        } else {
          this.loading=false;
          Toast({
            type: "error",
            title: '操作失败，请联系管理员',
          });
        }
      }).catch((err)=>{
            Toast({
              type: "error",
              title: '操作失败，请联系管理员',
            });
          }

      )
    },
    // 下拉加载
    pageScroll(){
      // 获取滚动的距离
      const scrollTop=document.documentElement.scrollTop;
      // 获取滚动的高度（获取整个html的高度）
      const scrollHeight=document.documentElement.scrollHeight;
      // 获取屏幕(浏览器)高度
      const clienHeight=document.documentElement.clientHeight;
      // console.log(scrollTop)
      // 滚动的距离 + 屏幕高度 - 内容高度 >= 0 表示滚动到底部了      (下拉加载判断条件)
      if(scrollTop==0){
        return false
      }
      if( this.isGetList){
        if(scrollTop+clienHeight-scrollHeight >=0){
          //下拉底部则请求下一页数据填充，若无数据则不请求
          this.loading=true
          this.page+=1;
          //滚动至底部后请求数据
          axios.post("/M/couponlog/list?page=" +this.page)
              .then((res) => {
                this.loading=false
                if (res.data.success) {
                  this.couponList= [... this.couponList,...res.data.data];
                  if (res.data.data.length == 0) {
                    Toast({
                      type: "warning",
                      title: '暂无更多优惠券',
                    });
                    this.isGetList=false
                  }else {
                    // Toast({
                    //     type: "default",
                    //     title: '加载中...',
                    // });

                  }

                } else {

                  Toast({
                    type: "warning",
                    title: '系统错误，请联系管理员',
                  });
                }
              })
              .catch((err) => {
                this.loading=false
                Toast({
                  type: "warning",
                  title: err.message,
                });
              });

        }
      }

    },
    //选定好友
    getFollow(id: any) {
      this.followGet = id
      // console.log(this.couponId, this.followGet)
    },
    //获取优惠券列表
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    getCouponList() {

    },
    closeModal() {
      this.showModal = false;
      this.searchText=''
    },
    //确定送出
    DocouponGive() {
      console.log(this.followGet)
      if(this.followGet){
        axios.post("/M/couponlog/couponGive?uid=" + this.followGet + '&couponId=' + this.couponId)
            .then((res) => {
              if (res.data.success) {
                Toast({
                  title: res.data.msg,
                  type: "success",
                });
                // 送完更新优惠券
                this.getCouponList1()
              } else {
                Toast({
                  title: res.data.msg,
                  type: "warning",
                });
              }
              this.closeModal()
            })
            .catch((err) => {
              Toast({
                title: err.message,
                type: "warning",
              });
            });
      }else {
        Toast({
          type: "error",
          title: '请先勾选要赠送的人',
        });


        this.closeModal()
      }
    },
    //点击赠送弹窗获取好友
    giveCoupon(id: any) {
      this.loading=true;
      this.couponId = id;
      axios.post("/M/couponlog/followList")
          .then((res) => {
            if (res.data.success) {
              this.loading=false;
              this.followList = res.data.data;
              if( Array.isArray(this.followList)) {
                // if(this.followList.length===0){
                //   Modal({
                //     title: "提示",
                //     content: "抱歉，您暂时还没有好友，是否添加好友",
                //     onConfirm: () => {
                //       this.$router.push('/Personal')
                //     }
                //   })
                //   return false
                // }
                this.showModal = true
              }

            } else {
              Toast({
                type: "error",
                title: '系统错误，请联系管理员',
              });
            }

          })
          .catch((err) => {
            Toast({
              type: "error",
              title: '系统错误，请联系管理员',
            });
          });
    }
  },
  beforeUnmount () {
    // 移除监听
    window.removeEventListener('scroll', this.pageScroll, true)
  },
});
